import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = ["| KT망에 이어 SKT, U+망까지… 자사 플랫폼 내 3사망 오픈, 고객선택권 강화"]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/42/42_img1.png" style={{ width: "300px", margin: "auto" }} alt="" />
      </div>
      <div className="desc">&lt;(위) 핀다이렉트 로밍패스&gt;</div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)는 2일 자사 통신플랫폼인 핀다이렉트 내 모든 요금제 상품에 SKT, KT, Uplus망을 적용하였다고 밝혔다.
      기존 KT망을 제공하던 것에 이어 SKT, U+망으로 전격 확대하면서, 망별 선호에 따른 선택의 폭을 넓혀 고객선택권을 보다 강화하게
      된 셈이다.
      <br />
      <br />
      핀다이렉트 내 요금제는 소비자의 데이터 라이프스타일과 선호도에 따라 Z 시리즈와 Prime 시리즈, eXtra 시리즈 등으로 구성되어
      있다. 특히, 핀다이렉트만의 특별한 혜택을 더한 요금제인 핀다로 요금제와 Prime 요금제가 눈여겨볼 만하다. 3사망은 USIM뿐 아니라
      eSIM에도 적용되며, eSIM을 선택할 경우 배송, SIM 교체 등 번거로운 절차 없이 핀다이렉트앱 내에서 5분이면 편리하고 원활하게
      개통이 가능하다.
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/42/42_img2.png" style={{ width: "300px", margin: "auto" }} alt="" />
        </div>
        <div className="desc">&lt;(왼)핀다로 요금제 (오)Prime 요금제&gt;</div>
      </div>
      <br />
      스테이지파이브 관계자는 “이번 3사망 확대를 계기로 합리적이고 편리한 통신라이프를 선도하는데 스테이지파이브가 기여하고
      싶다”며, “향후에도 고객 니즈를 세심히 파악하고 서비스를 강화하는 등의 노력을 지속할 계획”이라고 밝혔다.
      <br />
    </p>
  </div>
)

const press42 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 11월 2일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 핀다이렉트 내 3사망 도입 "
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press42
